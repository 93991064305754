



































































































































































































































































































































































































































































.grid-container {
  display: grid;
  grid-template-columns: auto auto;

  > div{
    padding: 3px;
    img{
      cursor:pointer;
      max-width: 100%;
      height: auto;
    }
  }
}

.border-container {
  border: 1px solid #e2e7f1; /* Set the border around the whole area */
  padding: 5px 10px; /* Add padding for better spacing */
  display: inline-block; /* Adjust display to your layout needs */
  width: 100%;
}

.border {
  display: flex;
  align-items: center;
}

.color-input {
  margin-left: 15px;
  border: 0;
  width: 30px; /* Set width */
  height: 30px; /* Set height */
}

.image-container:hover{
  .edit-button{
    visibility: visible !important;
  }
}
.edit-button{
  margin-top: -4px;
  vertical-align: top;
  color: dodgerblue;
  button{
    border: 2px solid #23b1dc;
    color: #23b1dc;
  }
}
  .social-img{
    max-width: 100%;
    height: auto;
    max-height: 630px;
  }
  .image-container {
    margin-bottom: -10px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .image-checkbox {
    display: none;
  }

  .image-label {
    cursor: pointer;
    position: relative;
  }


  .image-name {
    margin-left: 10px;
  }
  .image-radio {
    display: none;
  }
  .img-background{
    width:200px;
    height: 105px;
    display: inline-block;
    background-size: 100%;
  }
  /* Add custom styles to dim the background of selected options */
  .image-checkbox:checked + .img-background {
    border:2px solid #4CAF50;
    opacity: 1;
  }
.img-background:hover{
  opacity: .8 !important;
}
  /* Add custom styles for selected image */
  .image-radio:checked + .image-label .img-background {
    opacity: 1;
  }
